import Vue from 'vue'
import Breakpoints from '../vue/breakpoints'
import LazyLoad from "vanilla-lazyload";
import ScrollOut from "scroll-out";

// Intiate Vuejs instance
var app = new Vue({
    el: '#app',
    delimiters: ["${","}"],
    components: {
        Breakpoints,
    }
})

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {
    // Team member read more drop down
    let teamMemberDescLinks = document.querySelectorAll('.team-member-desc-link');
    if(teamMemberDescLinks.length != 0) {
        teamMemberDescLinks.forEach(function(item){
            item.addEventListener('click', function(){
                this.previousElementSibling.classList.toggle('team-member-desc-full');
                this.previousElementSibling.previousElementSibling.classList.toggle('team-member-desc-short');
                isHidden(this.previousElementSibling) ? this.innerHTML = 'Read more' : this.innerHTML = 'Read less';
            });
        });

    }

    // Styling reCaptcha
    let reCaptchaContainer = document.querySelector('.g-recaptcha');
    if(reCaptchaContainer != null) {
        reCaptchaContainer.parentElement.classList.add('g-recaptcha-container');
    }
    

    fadeInPage();

    // Page transition functionality
    function fadeInPage() {
        if (!window.AnimationEvent) { return; }
        var fader = document.getElementById('fader');
        if(fader != null) {
            fader.setAttribute('class', 'fade-out');
        }

    }

    if (!window.AnimationEvent) { return; }

    var anchors = document.querySelectorAll('.fade-link');

    for (var idx=0; idx<anchors.length; idx+=1) {
        if (anchors[idx].hostname !== window.location.hostname) {
            continue;
        }
        anchors[idx].addEventListener('click', function(event) {
                var fader = document.getElementById('fader'),
                    anchor = event.currentTarget;
                var listener = function() {
                    window.location = anchor.href;
                    fader.removeEventListener('animationend', listener);
                };
                fader.addEventListener('animationend', listener);
                event.preventDefault();
                fader.setAttribute('class', 'fade-in');
        });
    }


    // Scroll out - scroll-based trigger for animations
    ScrollOut({
        once: true,
    });


    // Lazyload
    var lazy = new LazyLoad({
        elements_selector: ".lazy"
    });

    // Mobile menu
    var navTrigger = document.querySelector(".js-open-nav");
    var mobNav = document.querySelector(".mobile");

    if(navTrigger){
        bindEvent(navTrigger, "click", function() {
            navTrigger.classList.toggle("is-active");
            mobNav.classList.toggle("js-is-open");
        }, false);
    }


    // Accordions
    const accordionItems = document.querySelectorAll('[data-accordion-item]');
    const accordionContentPanes = document.querySelectorAll('[data-accordion-content]');
    if (accordionItems.length) {
        accordionItems.forEach(function(accordion) {
            const accordionTitleRow = accordion.firstElementChild;
            bindEvent(accordionTitleRow, 'click', toggleAccordion);
        });
    }

    function toggleAccordion(e) {

        if(e.target.parentElement.classList.contains('accordion-item--active')) {
            e.target.parentElement.classList.remove('accordion-item--active');
            return;
        }
        accordionContentPanes.forEach(function(content) {
            if (content.previousElementSibling === e.target) {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.classList.remove('accordion-item--active');
                }
                content.parentElement.classList.add('accordion-item--active');
            } else {
                if(!("accordionAllowMultiple" in content.parentNode.dataset)) {
                    content.parentElement.classList.remove('accordion-item--active');
                }
                content.classList.add('accordion-item--active');
            }
        });
    }

    function bindEvent(el, eventName, eventHandler) {
        if (el.addEventListener){
            el.addEventListener(eventName, eventHandler, false);
        } else if (el.attachEvent){
            el.attachEvent('on'+eventName, eventHandler);
        }
    }

})

function isHidden(el) {
    var style = window.getComputedStyle(el);
    if (style.display === 'none') {
        return true;
    }
}

